import { IApiRequestHandler } from "../util/APIRequestHandler";
import defaultStore from "../util/Globale";
import { ActionItemResponse, ChartResponse } from "../Schema";
import { School, ServerErrorResponse } from "../adapters/ApiSchema";
import { getFromLS } from "../util/storage";
import { createImpersonateUrlBuilder } from "./Common";

export interface DcpsConfig {
    DynamicNav: boolean
}
export interface IDcpsService {
    getContentStream(nextUrl: string): Promise<ActivityFeedResp | ServerErrorResponse>
    getAllSchools(): Promise<School[] | ServerErrorResponse>
    getUserProfileInfo(): Promise<any>
    getUserActionItems(): Promise<ActionItemResponse[] | ServerErrorResponse>
    getBingMapAPI(): Promise<BingMapResponse | ServerErrorResponse>
    getCharts(opts: { id: string | undefined, upn: string | undefined }): Promise<ChartResponse | ServerErrorResponse>
    getConfig(): Promise<DcpsConfig | ServerErrorResponse>
    getLikeInfo(likeInfoUrl: string): Promise<LikeInfoResp | ServerErrorResponse>
    setLike(url: string): Promise<LikeUnlikeResp | ServerErrorResponse>
}

export interface BingMapResponse {
    Key: string
}

export function tryGetUpnOverride(): string | undefined {
    const overrideUpn = getFromLS<string>('upn');
    if (!!overrideUpn) {
        console.warn("upn override", overrideUpn);
        if (overrideUpn.includes("@")) {
            return overrideUpn as string;
        } else if (overrideUpn[0] === 'S') {
            return overrideUpn + '@students.duvalschools.org';
        } else if (overrideUpn[0] === 'P' && overrideUpn[1] === '-') {
            return overrideUpn + '@public.duvalschools.org';
        } else {
            console.warn('upn does not include @, maybe malformed');
            return overrideUpn as string;
        }
    }
}

export interface ActivityFeedItemProps {
    ProviderName?: string
    ProviderDisplayName?: string
    ProviderID: string
    ImageUrl: string
    DisplayUrl: string
    Author: string
    HasAuthor: boolean
    AuthorImageUrl: string
    Subject?: string
    Summary?: string
    Modified: string
    TimeAgo: string
    HasEmbeddedVideo: boolean
    LikeInfoUrl: string | undefined
    LikeClickUrl: string | undefined
    LikeUnclickUrl: string | undefined
}

export interface ActivityFeedResp {
    Items: ActivityFeedItemProps[]
    NextURL: string
}
export interface LikeInfoResp {
    Username: string
    HasLiked: boolean
    TotalLikes: number
    TotalClicks: number
}
export interface LikeUnlikeResp {
    TotalLikes: number
    Metadata: object
}

export default class DcpsService implements IDcpsService {

    private dcpsApiClient: IApiRequestHandler
    // private _upn: string | undefined = undefined;

    constructor(apiClient: IApiRequestHandler) {
        const overrideUpn = tryGetUpnOverride();
        this.dcpsApiClient = apiClient;
        (window as any)['getWhoAmI'] = () => this.getWhoAmI().then(x => console.log('IAM', x?.data || x));
        this.buildUrl = createImpersonateUrlBuilder(this.dcpsApiClient.getHostName(), overrideUpn);
    }

    buildUrl: (relPath: string, queryParams?: { key: string, value: string }[]) => string

    async getContentStream(nextUrl: string, searchText?: string): Promise<ActivityFeedResp | ServerErrorResponse> {
        var url = "";
        if (nextUrl !== "") {
            url = nextUrl;
        } else {
            // url = addQueryParamIfValue(urlBase, "search", searchText);
            url = this.buildUrl(defaultStore.webAPIEndPointGetContentStream, [{ key: "search", value: searchText ?? "" }])
        }
        console.log("getActivityFeedItems", url, nextUrl, searchText);

        return await this.dcpsApiClient.getOrDelete(url, {});
    }
    async getAllSchools(): Promise<School[] | ServerErrorResponse> {
        let url = this.dcpsApiClient.getHostName() + defaultStore.webAPIEndPointSchools;

        return await this.dcpsApiClient.getOrDelete<School[]>(url, {});
    }
    async getWhoAmI(): Promise<any> {
        let url = this.dcpsApiClient.getHostName() + defaultStore.webAPIEndPointWhoAmI;
        return await this.dcpsApiClient.getOrDelete(url, {});
    }
    async getUserProfileInfo(): Promise<any> {
        let url =
            this.dcpsApiClient.getHostName() + defaultStore.webAPIEndPointUserProfileInfo;

        return await this.dcpsApiClient.getOrDelete(url, {});
    }
    async getUserActionItems(): Promise<ActionItemResponse[] | ServerErrorResponse> {
        console.log('getting user action items');
        let url = this.buildUrl(
            defaultStore.webAPIEndPointActionItems, //https://jrapi-dev-2006.azurewebsites.net/api/DCPS/ActionItems
            []);
        let r = this.dcpsApiClient.getOrDelete<ActionItemResponse[]>(url, {})
        return r;
    }
    async getCharts(opts: { id: string | undefined, upn: string | undefined }): Promise<ChartResponse | ServerErrorResponse> {
        const url = this.dcpsApiClient.getHostName() + defaultStore.webAPIEndPointCharts;
        return await this.dcpsApiClient.getOrDelete(url, { params: opts });
    }
    async getBingMapAPI(): Promise<BingMapResponse | ServerErrorResponse> {
        let url = this.dcpsApiClient.getHostName() + "/api/dcps/bingMapApiKey";

        return await this.dcpsApiClient.getOrDelete(url, {})
    }

    async getConfig(): Promise<DcpsConfig | ServerErrorResponse> {
        let url = this.dcpsApiClient.getHostName() + "/api/dcps/config";
        return await this.dcpsApiClient.getOrDelete(url, {});

    }

    async getLikeInfo(likeInfoUrl: string): Promise<LikeInfoResp | ServerErrorResponse> {
        return await this.dcpsApiClient.getOrDelete(likeInfoUrl, {});
    }

    async setLike(url: string): Promise<LikeUnlikeResp | ServerErrorResponse> {
        return await this.dcpsApiClient.getOrDelete(url, {});
    }

}