// aggregate root for the home page / feed aggregator / feed search
// tie together those things without 'much' concern for the ui

// allowed exceptions : scroll to top?

import { assertUnreachable } from "../Reusable";
import { newDcpsService } from "../util/Services";
import { isServerErrorResponse, ServerErrorResponse } from "./ApiSchema";
import { scrollToTop } from "./windowUtils";

import { ActivityFeedItemProps, ActivityFeedResp } from "../services/DCPS"
import { formatDate } from "../services/Common";
import searchSlice, { LikeUnlikeMsg, makeSearchCmdDispatch, searchActions, SearchCmd, SearchCmdDispatch, SearchMsg, SearchState, tryGetCurrentItems } from "../store/search-slice";

// if this is a server shape, move it to schema
export type PostItemMetaInfo = {
    likes: number
    iLike: boolean
    totalClicks: number
}

interface PostItemProps {
    thumbnail: string
    title: string
    providerName: string
    link: string
    date: string
    dateTitle: string
    providerDisplayName: string
    summary: string
    hasAuthor: boolean
    authorImageUrl: string | undefined
    getLikeInfoUrl: string | undefined
    addLikeUrl: string | undefined
    removeLikeUrl: string | undefined
    postItemMetaInfo?: PostItemMetaInfo
}

// type alias, additive type demo
// this one prop could be rolled into the above type, but keeping this here to show and explain
export type ServerPostItem = { id: string } & PostItemProps;

// a fresh fetch, initiated by landing, not by searching
export type FetchInit = {
    state: 'init'
}
export type FetchRequest = {
    state: 'req'
    searchText: string
}
export type FetchContinue = {
    state: 'cont'
    nextUrl: string
}

export type PostFetchArgs =
    | FetchInit
    | FetchRequest
    | FetchContinue


export const END_OF_FEED = "endOFfeed";

export const canLoadPosts = (pfa: PostFetchArgs) => !('nextUrl' in pfa) || pfa.nextUrl !== END_OF_FEED;


interface ReplyResult {
    nextUrl: string
    newItems: ServerPostItem[]
    initiallyLoading: boolean
    loading: boolean
}

type PReplyResult = Partial<ReplyResult>

export type HandleReplyResult = ({ searchChanged: boolean } & PReplyResult) | ServerErrorResponse
// const handleReply = (dispatch:any, [initiallyLoading,setInitiallyLoading]: System.PropWrapper<boolean>, [items, setItems]: System.PropWrapper<ServerPostItem[]>, [nextUrl,setNextUrl]: System.PropWrapper<string>, searchChanged: boolean, res: ActivityFeedResp | ServerErrorResponse) => {

const handleReply = (nextUrl: string, searchChanged: boolean, res: ActivityFeedResp | ServerErrorResponse):
    HandleReplyResult => {
    // console.log("Posts loaded with result: ", res);
    // console.log('handleContentStreamReply');
    let result: Partial<ReplyResult> & { searchChanged: boolean } = { searchChanged };
    if (isServerErrorResponse(res)) {
        console.warn(res);
        return res;
    }
    if (res.Items.length === 0 || !res.Items) {
        result.nextUrl = END_OF_FEED;
    } else if (nextUrl !== res.NextURL) {

        console.log('loading ' + res.Items.length + ' post(s), searchChanged:' + searchChanged, res.NextURL);

        // possible memory leak
        // in case token refreshes, triggering a duplicate load
        result.nextUrl = res.NextURL;

        result.newItems = res.Items.map(mapServerItem);
    }
    result.initiallyLoading = false;

    result.loading = false;

    // console.log('getContentStream done!');
    return result;
}

// export const fetchPosts = async (pfa: PostFetchNotifyArgs): Promise<undefined | HandleReplyResult> => {
export const fetchPosts = (dispatch: Function) => async (key: string, pfa: PostFetchArgs): Promise<undefined | ReturnType<typeof handleReply>> => {
    if (!canLoadPosts(pfa)) {
        console.warn('cant load posts', JSON.stringify({ key, pfa }));
        return;
    }

    console.log('loading posts', JSON.stringify({ key, pfa }));

    var searchChanged = !('nextUrl' in pfa);

    let nextUrl = 'nextUrl' in pfa ? pfa.nextUrl : "";
    let searchText = 'searchText' in pfa ? pfa.searchText : "";
    let req = newDcpsService.getContentStream(nextUrl ?? "", searchText ?? ""); //.then(res => handleReply(items, searchChanged, res));
    let msg: SearchMsg = {
        type: 'PostLoadRequestFiredMsg',
        searchInput: searchText ?? "",
        url: nextUrl ?? ""
    }
    let cmdDispatch = makeSearchCmdDispatch(dispatch, runCmd);
    dispatch(searchActions.update({ msg, cmdDispatch }))
    let result = await req;
    console.log('getContentStream done');
    return handleReply(nextUrl, searchChanged, result);
};

const mapServerItem = (item: ActivityFeedItemProps): ServerPostItem => {

    var spi: ServerPostItem = {
        addLikeUrl: item.LikeClickUrl,
        authorImageUrl: item.AuthorImageUrl,
        date: item.TimeAgo,
        dateTitle: formatDate(item.Modified),
        getLikeInfoUrl: item.LikeInfoUrl,
        hasAuthor: item.HasAuthor,
        id: item.ProviderID,
        link: item.DisplayUrl,
        providerDisplayName: item.ProviderDisplayName ?? "",
        providerName: item.ProviderName ?? "",
        removeLikeUrl: item.LikeUnclickUrl,
        summary: item.Summary ?? "",
        thumbnail: item.ImageUrl,
        title: item.Subject ?? "",
    };
    // Show nothing if the summary provides no extra insight
    if (spi.title.normalize() === spi.summary.normalize()) {
        spi.summary = "";
    }
    return spi;
}

// does not attempt to remove dupes from existing items, only prevent adding new dupes
export const getItemsNext = (keepPrevious: boolean, existingItems: ServerPostItem[], newItems: ServerPostItem[]): ServerPostItem[] => {
    let existingIds = new Set(keepPrevious ? existingItems.map(x => x.id) : []);
    // if (!getIds().includes(_item.id) && (!keepPrevious || (keepPrevious && !existingIds.includes(_item.id)))) {
    //     _items.push(_item);
    let toMerge = newItems.filter(x => {
        let isDupe = existingIds.has(x.id);
        existingIds.add(x.id);

        return !isDupe;
    });

    if (keepPrevious) {
        // let oldCount = items.length;
        // console.log('merging from ' + oldCount + ' item(s) with ' + _items.length + " new item(s)");

        let nextItems = [...existingItems, ...toMerge];
        const ids = nextItems.map(x => x.id);
        const distinctIds = Array.from(new Set(ids)).sort();
        if (nextItems.length !== distinctIds.length)
            console.warn('duplicate ids found', ids.length, distinctIds.length, ids);
        // console.log('merge completing, ' + oldCount + ' -> ' + items.length + ' item(s)', nextItems.map(x => x.id));
        return nextItems;
    } else {
        // console.log('replacing, expect to see ' + _items.length);
        return toMerge;
        // console.log('replaced with ' + _items.length);
    }
};

export const onLikeLoadRequestHandler = (dispatch: Function, title: string) => (item: ServerPostItem): void => {
    if (!item) {
        console.error("onLikeLoadRequestHandler: item undefined", title);
        return;
    }

    if (item.getLikeInfoUrl) {

        newDcpsService.getLikeInfo(item.getLikeInfoUrl).then(resp => {
            // console.log('like resp', resp);
            if (isServerErrorResponse(resp)) {

            } else {
                let itemLikeInfo: PostItemMetaInfo = {
                    likes: resp.TotalLikes,
                    iLike: resp.HasLiked,
                    totalClicks: resp.TotalClicks
                };

                let msg: LikeUnlikeMsg = { type: "LLoadResponseMsg", item: item, likeInfo: itemLikeInfo }
                let cmdDispatch = getSearchCmdDispatch(dispatch)

                dispatch(searchSlice.actions.updateLikeUnlike({
                    msg,
                    cmdDispatch
                }));
            }
        });
    } else {
        console.error("getLikeInfoUrl", item.id, item.getLikeInfoUrl);
    }
};

export const onLikeServerRequest = (dispatch: Function, cmdDispatch: SearchCmdDispatch) => (userSearchState: SearchState) => (itemId: string): Promise<void> | undefined => {
    // console.log('onLikeServerRequest', JSON.stringify({item: item? item : 'undefined'}));
    // if (item.likeInfo === undefined) return;
    if (!itemId) {
        console.log('onLikeServerRequest: no itemId', JSON.stringify({ itemId: itemId ? itemId : 'undefined' }));
        return;
    }
    let items = tryGetCurrentItems(userSearchState);
    if (!items) {
        console.log('onLikeServerRequest: no items', JSON.stringify({ itemId, shownKey: userSearchState.currentShownKey, inFlight: userSearchState.inFlightSearch }));
        return;
    }
    let item = items?.items.find(x => x.id === itemId);

    if (!item) {
        console.log('onLikeServerRequest: itemNotFound', JSON.stringify({ itemId, shownKey: userSearchState.currentShownKey, inFlight: userSearchState.inFlightSearch }));
        return;
    }

    if (!item.postItemMetaInfo) {
        console.log('onLikeServerRequest: likeInfo missing', JSON.stringify({ itemId, itemId2: item.id, shownKey: userSearchState.currentShownKey, inFlight: userSearchState.inFlightSearch }));
        return;
    }

    // in case iLike is null or undefined
    let iLike = item.postItemMetaInfo.iLike === true;

    let url = iLike ? item.removeLikeUrl : item.addLikeUrl;
    if (!url) {
        console.error('onLikeClick failed to read url', item.id, item.postItemMetaInfo);
        return;
    }
    let msg: LikeUnlikeMsg = {
        type: "LRequestMsg",
        item: item,
        like: !iLike
    }
    // let cmdDispatch: CmdDispatch<SearchCmd> = runCmd(dispatch);

    // dispatch eager like flip
    dispatch(searchSlice.actions.updateLikeUnlike({ msg, cmdDispatch }));

    return newDcpsService.setLike(url).then(res => {
        if (isServerErrorResponse(res)) {
            console.error('')
        } else {
            let msg: LikeUnlikeMsg = {
                type: "LikeLoadRequestMsg",
                item: item as ServerPostItem,
                totalLikes: res.TotalLikes
            }
            // setItemLike(item.id, { iLike: !iLike, likes: res.TotalLikes })
            dispatch(searchActions.updateLikeUnlike({ msg, cmdDispatch }));
            // console.log('finished set like!');
        }
    });
};

// message pump handler for routing search commands back into dispatch
let runCmd = (dispatch: Function) => (cmd: SearchCmd) => {
    console.log('runCmd', cmd.type);
    let cmdDispatch = getSearchCmdDispatch(dispatch)

    switch (cmd.type) {
        case "ScrollUpCmd":
            scrollToTop();
            return;
        case "LoadPostsCmd":
            let postPromise = fetchPosts(dispatch)(cmd.continueInfo?.key ?? "", cmd.continueInfo?.url ? { state: 'cont', nextUrl: cmd.continueInfo.url } : cmd.continueInfo?.key ? { state: 'req', searchText: cmd.continueInfo.key } : { state: 'init' })
            postPromise.then(res => {
                if (!res) {
                    console.error("LoadPostsCmd", "undefined response");
                    return;
                }
                if (isServerErrorResponse(res) || !res.newItems) {
                    console.error("LoadPostsCmd", JSON.stringify(res));
                    return;
                    // consider registering an error
                }

                let msg: SearchMsg = {
                    type: 'PostsLoadResponseMsg',
                    key: cmd.continueInfo?.key ?? "",
                    items: res.newItems,
                    nextUrl: res.nextUrl
                };

                dispatch(searchActions.update({ msg, cmdDispatch }));

            });
            return;
        case "LoadLikeInfoCmd":
            // TODO: implementation
            return;

    }
    return assertUnreachable(cmd);
}

// HACK: fix for a cmd pump inside the reducer
export let getSearchCmdDispatch = (dispatch: Function) => (cmds: SearchCmd[]) => setTimeout(makeSearchCmdDispatch(dispatch, runCmd), 80, cmds);