import React, {
  useEffect,
  useCallback,
} from "react";

import { useDispatch, useSelector } from "react-redux";

import { styled } from '@mui/material/styles';
import { Card, CardHeader, CardContent, Typography, Skeleton, CircularProgress, useMediaQuery, Paper } from '@mui/material';

import { grey } from '@mui/material/colors';
import { useTheme } from "@mui/material/styles";

import { JsEvent, withScrollingTarget } from "../../../adapters/Eventing";
import { isWindowBottom, queryKnownMatchMedia } from "../../../adapters/windowUtils";
import { System, debounce } from "../../../Reusable";

import { RootState } from "../../../store";
import { PostItemView } from "./ServerPostComponent";
import { END_OF_FEED, getSearchCmdDispatch, onLikeLoadRequestHandler, onLikeServerRequest } from "../../../adapters/FeedAdapter";
import { searchActions, SearchCmdDispatch, SearchMsg, SearchState, tryGetCurrentItems } from "../../../store/search-slice";
import { useWindowScrollListener } from "../../../util/ReactReusable";
import { AppNavBarHeight } from "../../../components/appBar/AppNavBar";
import { VideoView } from "./VidComponent";

export const MAX_WIDTH = 650;


const PREFIX = 'CDS';

export const classes = {
  feed: `${PREFIX}-feed`,
  shimmer: `${PREFIX}-shimmer`,
  hover: `${PREFIX}-hover`,
  actionBarIconText: `${PREFIX}-actionBarIcons`,
  like: `${PREFIX}-like`,
  loadIcon: `${PREFIX}-loadIcon`,
  share: `${PREFIX}-share`,
  sharePostCopy: `${PREFIX}-sharePostCopy`,
  container: `${PREFIX}-container`,
  containerShimmer: `${PREFIX}-containerShimmer`,
  blur: `${PREFIX}-blur`
};

// exported only for MvuFeed, remove later if we can change over to it
// TODO: review if some of these classes need to be migrated into ServerPostComponent
export const Root = styled('div')(({ theme }) => ({
  [`& .${classes.feed}`]: {
    display: "flex",
    flexDirection: "column",
    width: MAX_WIDTH,
    [theme.breakpoints.down('md')]: {
      width: 'auto',
    }
  },
  [`& .${classes.shimmer}`]: {
    top: 70
  },
  [`& .${classes.hover}`]: {
    cursor: "pointer",
  },
  [`& .${classes.actionBarIconText}`]: {
    // lineHeight: '.5em'
    fontSize: '.75em'
  },
  [`& .${classes.like}`]: {
    display: 'flex',
    width: 175,
    backgroundColor: 'transparent',
    transition: theme.transitions.create(['color', 'background-color', 'padding-left'], {
      duration: theme.transitions.duration.complex,
    }),
    cursor: "pointer",
  },

  [`& .${classes.loadIcon}`]: {
    alignSelf: "center",
    marginBottom: 20,
  },

  [`& .${classes.share}`]: {
    display: 'flex',
    width: 175,
    backgroundColor: 'transparent',
    transition: theme.transitions.create(['color', 'background-color', 'padding-left'], {
      duration: theme.transitions.duration.complex,
    }),
    cursor: "pointer",
  },

  [`& .${classes.sharePostCopy}`]: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.primary.contrastText,
    alignItems: 'center',
    paddingLeft: 10,
    cursor: "default",
    pointerEvents: "none",
  },
  [`& .${classes.container}`]: {
    marginBottom: 20,
    boxShadow: "1px 1px 10px #dbdbdb",
    [theme.breakpoints.down('sm')]: {
      maxWidth: 318
    }
  },
  [`& .${classes.containerShimmer}`]: {
    width: '85vw',
    maxWidth: MAX_WIDTH,
  },
  [`& .${classes.blur}`]: {
    position: 'relative',
    width: '100%',
    height: '80px',
    marginTop: '-60px',
    backgroundImage: 'linear-gradient(to bottom, rgba(255,255,255,0) 0%, white 80%, white 100%)',
  }
}));

/*
    Displayed while loading
*/
// exported for mvuFeed
export const Shimmer = () => {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Card raised={true} className={`${classes.container} ${classes.containerShimmer}`}>
      <CardHeader //TODO get avatar from backend
        avatar={<Skeleton animation="wave" variant="circular" width={40} height={40} />}
        action={null}
        title={<Skeleton animation="wave" height={10} width="80%" style={{ marginBottom: 6 }} />}
        subheader={<Skeleton animation="wave" height={10} width="40%" />}
        min-width={'90vw'} // Prevent card from shrinking too much
      />
      <Skeleton height={sm ? 300 : 500} animation="wave" variant="rectangular" />
      <CardContent>
        <React.Fragment>
          <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
          <Skeleton animation="wave" height={10} width="80%" />
        </React.Fragment>
      </CardContent>
    </Card>
  );
}

export const SS_PREF = "CDS";

const dummyItems = new Array(9).fill(0);



// hot reload is causing nextUrl to fire getting the next items
const Feed = () => {

  // const [postItems, setPostItems] = useState<ServerPostItem[]>([]);
  // const [nextUrl, setNextUrl] = useState<string>("");
  const userSearchState = useSelector((state: RootState) => state.search);

  let currentItems = tryGetCurrentItems(userSearchState);
  // should we use loading or initially loading for search change?
  // is this value remotely reliable now?
  // const [initiallyLoading, setInitiallyLoading] = useState<boolean>(true);
  let initiallyLoading = userSearchState.currentShownKey === undefined || currentItems === undefined;

  let isSmallDevice = queryKnownMatchMedia('isSmallDevice') ?? false;
  // console.log("Feed", { items: postItems.length, hasInvokeRef: searchInvokeRef.current !== null });
  // console.log("Feed", currentSearch);

  const dispatch = useDispatch();
  // TODO: update with proper dispatch creator
  let cmdDispatch = getSearchCmdDispatch(dispatch)

  // Reset scroll
  useEffect(() => {
    // console.log('Feed re-init');
    window.scrollTo(0, 0);
    //whoAmI(newToken).then(res=>console.log(res));
    return () => {
      window.scrollTo(0, 0);
    };
  }, []);


  const onLikeLoadRequest = useCallback(onLikeLoadRequestHandler(dispatch, 'CDS'), [dispatch]);

  const onLikeClick = (dispatch: Function, cmdDispatch: SearchCmdDispatch) => (ss: SearchState) => (itemId: string): Promise<void> | undefined => {
    return onLikeServerRequest(dispatch, cmdDispatch)(ss)(itemId);
  };


  // TODO: missing dependencies?
  useEffect(() => {
    // If it's our initial load without cache, load with shimmer
    console.log('CDS reloading items', JSON.stringify({ loadingShownKey: userSearchState.loadingShownKey, inFlight: userSearchState.inFlightSearch }));
    if (initiallyLoading) {
      // determine if we have items loaded to show, if not load them
      let cItems = tryGetCurrentItems(userSearchState)
      if (userSearchState.currentShownKey === undefined || cItems === undefined) {
        let msg: SearchMsg = {
          type: 'PostsLoadRequestedMsg',
          searchInput: ""
        }
        dispatch(searchActions.update({ msg, cmdDispatch }))
        // setLoading(true);
        // let pfa: FetchInit = { state: "init" };
        // loadPosts("", pfa);
      } else {
        console.log('')
      }
    } else {
      console.log("CDS feed with already loading");
    }
  }, []); // adding the requested dependencies make this constantly refire

  // WARN: this is caching things it should not be using a cache for
  const onScrollBottom = useCallback(debounce((element: unknown) => {
    if (element && isWindowBottom()) {
      console.log('element and window bottom', initiallyLoading);
      // this is being cached
      if (!initiallyLoading) {
        console.log("bottom, loading:", currentItems?.nextUrl);
        // should we force the scroll to 1 above whatever is triggering this state or will the user's force scroll result in thrashing the window?
        window.scrollBy(0, -10);
        let msg: SearchMsg = {
          type: 'UserScrollToBottomMsg',
          key: userSearchState.currentShownKey
        }
        dispatch(searchActions.update({ msg, cmdDispatch }));
        // // do we care about last search? we're using nextUrl
        // if (!loading) {
        //   loadPosts({ nextUrl: nextUrl });
        // }
      }
    }

  }, 1000), [userSearchState.currentShownKey, initiallyLoading]);


  // Load again when we scroll to the bottom of the page (DESKTOP)
  useWindowScrollListener(withScrollingTarget((element: JsEvent) => { onScrollBottom(element); }))

  let searchBanner = !initiallyLoading ? System.String.appendIfValue(System.String.quotedIfValue(userSearchState.currentShownKey), "Search Results", true) : '';

  let cdsView = (
    <Root data-file="CDS" data-method="Feed">
      {
        !!searchBanner ? <header style={{ position: "sticky", top: AppNavBarHeight, width: "100%", zIndex: 999, marginBottom: 10 }}><Paper><p style={{ padding: 5 }}>{searchBanner}</p></Paper></header> : undefined
      }
      <div className={classes.feed}>
        {/* <div>
          <VideoView sources={""} />
        </div> */}
        {(currentItems?.items ?? []).map((item, index) => (
          <PostItemView key={index} postItem={item} isSmallDevice={isSmallDevice} onLikeUnlikeClick={itemId => onLikeClick(dispatch, cmdDispatch)(userSearchState)(itemId)} onLoadRequest={() => onLikeLoadRequest(item)} />
        ))}
        {userSearchState.loadingShownKey && <CircularProgress className={classes.loadIcon} />}
        {/* {(tryGetCurrentItems(userSearchState)?. === END_OF_FEED && <Typography align='center' variant='body2' style={{ marginBottom: 10 }} color={grey[400]}>End of Feed</Typography>} */}
        {(currentItems && (currentItems.nextUrl === undefined || currentItems.nextUrl === END_OF_FEED)) && <Typography align='center' variant='body2' style={{ marginBottom: 10 }} color={grey[400]}>End of Feed</Typography>}
      </div>
    </Root>
  );



  return (<>
    {initiallyLoading ?
      <Root data-file="CDS" data-method="Feed" data-state="initiallyLoading">
        <div className={classes.shimmer}>
          {dummyItems.map((_, index) => (
            <Shimmer key={index} />
          ))}
        </div>
      </Root>
      : cdsView
    }
  </>);
}

export default Feed;