// based on https://thomasbandt.com/model-view-update-with-react-and-typescript
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { assertUnreachable, Result, resultError, resultOk, System, tryUpdateByKey } from "../Reusable";
import { CmdDispatch, CmdRunner, makeCmdDispatch, UpdateCmdResult, UpdateHandler } from "./mvu";

// consider extracting these shapes to else where
import { getItemsNext, ServerPostItem } from "../adapters/FeedAdapter";

type ScrollUpCmd = {
    type: "ScrollUpCmd"
}

type ContinueInfo = {
    key: string
    url: string | undefined
}

// can this safely cover change of key, and scroll to bottom scenarios?
// and also initial load, and request new search scenarios?
type LoadPostsCmd = {
    type: "LoadPostsCmd"
    // undefined if this is a request for items fresh, or a fresh search
    continueInfo: ContinueInfo | undefined
}

type LoadLikeInfoCmd = {
    type: "LoadLikeInfoCmd"
    item: ServerPostItem
}

export type SearchCmd = ScrollUpCmd | LoadPostsCmd | LoadLikeInfoCmd

type ItemState = {
    nextUrl: string | undefined
    items: ServerPostItem[]
}
type DefaultItemState = ItemState & {
    key: ""
}

type KeyedItemState = ItemState & {
    key: string
}
export interface SearchState {

    // currentItems: KeyedItems | undefined
    // undefined for not loaded
    // empty for showing normal results, a string for showing search results
    // this reflects what we are currently showing search results for, or the default no-search results
    currentShownKey: string | undefined
    inFlightSearch: ContinueInfo | undefined
    isHome: boolean

    // this may become relevant if we pull up loaded items into here
    // unless we take the dictionary approach so search/non-search can toggle quickly and easily
    // lastSearch: string
    loadingShownKey: boolean

    postItemsDefault: DefaultItemState | undefined
    postItemsSearch: KeyedItemState | undefined

    searchFocused: boolean
    // searchInput: string
}

export const tryGetCurrentItems: ((ss: SearchState) => KeyedItemState | DefaultItemState | undefined) =
    ss => ss.currentShownKey ? (ss.postItemsSearch && ss.postItemsSearch.key === ss.currentShownKey ? ss.postItemsSearch : undefined) : ss.postItemsDefault;

export const trySetCurrentItems = (ss: SearchState) => (key: string, f: System.Func1<KeyedItemState | DefaultItemState | undefined, KeyedItemState | DefaultItemState | undefined>): SearchState | undefined => {
    let cItems = tryGetCurrentItems(ss);
    let result = f(cItems);
    if (!result) return undefined;
    if (result.key !== key) {
        console.error('trySetCurrentItems', JSON.stringify({ cKey: cItems?.key, key, ssKey: ss.currentShownKey }));
        return undefined;
    }

    let nextSS: SearchState = { ...ss, postItemsDefault: key ? ss.postItemsDefault : result as DefaultItemState, postItemsSearch: key ? result as KeyedItemState : ss.postItemsSearch };
    return nextSS;
};

export const tryUpdateItem = (ss: SearchState) => (item: ServerPostItem, f: System.Func1<ServerPostItem, ServerPostItem>): Result<SearchState, string> => {
    let cItems = tryGetCurrentItems(ss);
    if (!cItems) return resultError("No items found");
    let updateResult = trySetCurrentItems(ss)(cItems.key, cItems => {
        if (!cItems) return undefined;
        if (!item.id) {
            console.warn('no id found on item', item?.title)
        }
        if (cItems.key) {
            return { ...cItems, items: cItems.items.map(x => x.id === item.id ? f(x) : x) } as KeyedItemState;
        } else {
            return { ...cItems, items: cItems.items.map(x => x.id === item.id ? f(x) : x) } as DefaultItemState;
        }
    });
    if (!updateResult) return resultError("Update returning nothing");
    return resultOk(updateResult);
}

const initialState: SearchState = {
    // currentItems: undefined,
    currentShownKey: undefined,
    inFlightSearch: undefined,
    // is this a bad assumption?
    isHome: true,
    loadingShownKey: false,
    // lastSearch: "",
    postItemsDefault: undefined,
    postItemsSearch: undefined,
    searchFocused: false,
    // searchInput: "",
};

// assumes you can't click search focus, nor type any input before load finishes
// const initialLoad: SearchState = { ...initialState, loading: false, currentShownKey: "" };

// consider encoding current state in messages where appropriate
// so msgs that arrive when not relevant are not counted
// especially if they may appear to become relevant again later when evaluated

// type UserSearchInputChangedMsg = {
//     type: "UserSearchInputChangedMsg"
//     text: string
// }

type UserScrollToBottomMsg = {
    type: "UserScrollToBottomMsg"
    // capture the key that this handler thinks is the current shown key,
    // so if it doesn't match discard the msg
    // undefined so that scroll to bottom handlers (built before anything is loaded can bypass)
    key: string | undefined
}

type SearchClearMsg = {
    type: "UserSearchClearMsg"
}


type HomeStateChangedMsg = {
    type: "HomeStateChangedMsg"
    weAreHome: boolean
}

// assumes all searches on this path will have text
type PostsLoadRequestedMsg = {
    type: "PostsLoadRequestedMsg"
    searchInput: string
}

type PostLoadRequestFiredMsg = {
    type: "PostLoadRequestFiredMsg"
    searchInput: string
    url: string
}

type PostsLoadResponseMsg = {
    type: "PostsLoadResponseMsg"
    key: string
    items: ServerPostItem[]
    nextUrl: string | undefined
}

export type FocusType =
    | 'WantsFocus'
    | 'WantsUnfocus'
    | 'WantsRefocus'

type RefocusRequestedMsg = {
    type: 'RefocusRequestedMsg'
    key: string | undefined
    userRequest: boolean
    focusType: FocusType

}

// HACK: work around to let the existing component use the new state
// type CdsSetItemsMsg = {
//     type: "CdsSetItemsMsg"
//     nextItems: ServerPostItem[]

// }

export type SearchMsg =
    | HomeStateChangedMsg
    | PostLoadRequestFiredMsg
    | PostsLoadRequestedMsg
    | PostsLoadResponseMsg
    | RefocusRequestedMsg
    | SearchClearMsg
    | UserScrollToBottomMsg
// | CdsSetItemsMsg

type LikeLoadRequestMsg = {
    type: "LikeLoadRequestMsg"
    item: ServerPostItem
    totalLikes: number
}

type LLoadResponseMsg = {
    type: "LLoadResponseMsg"
    item: ServerPostItem
    likeInfo: Exclude<ServerPostItem['postItemMetaInfo'], undefined>
}

type LRequestMsg = {
    type: "LRequestMsg"
    like: boolean
    item: ServerPostItem
}

export type LikeUnlikeMsg = LLoadResponseMsg | LikeLoadRequestMsg | LRequestMsg

type SearchStateUpdateMsg = { msg: SearchMsg, model: SearchState };
// type UserStateMsg = (msg:UserSearchMsg, model: SearchState)
type SearchStateUpdateResult = UpdateCmdResult<SearchState, SearchCmd> // { newState: SearchState, cmds: SearchCmd[] }

// type CmdDispatch = (cmds: SearchCmd[]) => void

// type UserStateUpdate = (usi: UserStateUpdateMsg) => UserStateUpdateResult;

// export type UpdateResult = ReturnType<UserStateUpdate>;

export const updateItemsLikeInfo = (current: ServerPostItem[], item: ServerPostItem, likeInfo: Exclude<ServerPostItem['postItemMetaInfo'], undefined>): ServerPostItem[] =>
    tryUpdateByKey(current, item.id, x => {
        let itemX: ServerPostItem = { ...x, postItemMetaInfo: likeInfo };
        return itemX;
    });


let updateUserScrollToBottomMsg = (msg: UserScrollToBottomMsg, model: SearchState): SearchStateUpdateResult => {
    const doNothing: SearchStateUpdateResult = { newState: model, cmds: [] };

    let baseItems = tryGetCurrentItems(model)
    // what if the in flight is the wrong key? orphaned, should we not allow the current key to load more?
    if (model.inFlightSearch) {
        console.log(msg.type + ': while in flight', JSON.stringify(model.inFlightSearch));
        return doNothing;
    }

    if (!baseItems) {
        console.log(msg.type + ': before any items are loaded');
        return doNothing;
    }
    if (!baseItems.nextUrl) {
        console.log(msg.type + ': no next', JSON.stringify({ baseItems, msg }));

        return doNothing;
    }
    // scroll to bottom handler is created long before the initial load is finished, we don't need to block on key
    // if (msg.key !== currentKey) {
    //     console.log(msg.type + ': differing keys', JSON.stringify({ currentKey, msg }));
    //     return doNothing;
    // }

    if ((!msg.key || baseItems.key === msg.key) && baseItems.nextUrl) {
        return {
            newState: model, cmds: [
                { type: "LoadPostsCmd", continueInfo: { key: msg.key, url: baseItems.nextUrl } }
            ]
        } as SearchStateUpdateResult
    } else {
        console.warn(msg.type + ": key|nextUrl", JSON.stringify({ biKey: baseItems.key, msgKey: msg.key, nextUrl: baseItems.nextUrl }));
        return doNothing;
    }
}

let updateUserSearchClearMsg = (msg: SearchClearMsg, model: SearchState): SearchStateUpdateResult => {
    const currentKey = model.currentShownKey;
    const newSearchState: SearchState = { ...model, currentShownKey: model.postItemsDefault ? "" : undefined }
    if (currentKey && currentKey !== "") {
        // should this just revert to the default loaded items if they are there?
        let cmd: SearchCmd = { type: "LoadPostsCmd", continueInfo: undefined }
        return { newState: newSearchState, cmds: [cmd] }
    }
    return { newState: newSearchState, cmds: [] };
}

// this does not up date inFlight, because it creates a cmd that should msg back if it sends a request
let updatePostsLoadRequestedMsg = (msg: PostsLoadRequestedMsg, model: SearchState): SearchStateUpdateResult => {

    const currentKey = model.currentShownKey;
    // console.warn('search msg with mismatched search', JSON.stringify({ model: model, msgSearch: msg.searchInput }));

    // this could prevent refreshing a search, right? is that ok?
    // this will effectively just switch the current shown key if it isn't already the right key
    if (currentKey && model.postItemsSearch && model.postItemsSearch.key === msg.searchInput) {
        let ss: SearchState = { ...model, currentShownKey: msg.searchInput }
        return { newState: ss, cmds: [] };
    }

    let cmd: SearchCmd = { type: "LoadPostsCmd", continueInfo: { key: msg.searchInput, url: undefined } }
    let scrollUpCmd: SearchCmd = { type: "ScrollUpCmd" }

    let nextSS: SearchStateUpdateResult = {
        newState: { ...model, loadingShownKey: true, currentShownKey: undefined, postItemsDefault: currentKey ? model.postItemsDefault : undefined, postItemsSearch: currentKey ? undefined : model.postItemsSearch },
        cmds: [cmd, scrollUpCmd]
    }
    return nextSS;
}

//  let update msg model =
let updateBase: UpdateHandler<SearchState, SearchMsg, SearchCmd> = ({ msg, model }: SearchStateUpdateMsg) => {

    // if we homogenize search, this search condition must not block flow
    // what if we start loading, the user navigates to another page
    // the information comes in, why not capture the info?
    if (msg.type !== 'HomeStateChangedMsg' && !model.isHome) {
        console.warn('search msg while not home', msg, model);
    }
    const doNothing = { newState: model, cmds: [] };

    switch (msg.type) {

        case "UserScrollToBottomMsg":
            if (!model.isHome) return doNothing;
            return updateUserScrollToBottomMsg(msg, model)

        case "UserSearchClearMsg":
            // if we aren't home, should we also make sure focus is false?
            if (!model.isHome) return doNothing;

            return updateUserSearchClearMsg(msg, model);

        case "PostsLoadRequestedMsg":
            return updatePostsLoadRequestedMsg(msg, model);

        case "PostLoadRequestFiredMsg":
            return { newState: { ...model, inFlightSearch: { key: msg.searchInput, url: msg.url } }, cmds: [] };

        case "HomeStateChangedMsg":
            let needsUpdate = msg.weAreHome !== model.isHome;
            if (!needsUpdate) {
                return doNothing;
            }
            console.log(msg.type, { weAreHome: msg.weAreHome, wasHome: model.isHome, loadingShownKey: model.loadingShownKey, inFlight: model.inFlightSearch });
            // should we wipe out in flight, or reset anything else?
            // is this a good state change to re-initialize and reload the page's item for being possibly stale?
            return ({ newState: { ...model, isHome: msg.weAreHome }, cmds: [] } as SearchStateUpdateResult);

        case "PostsLoadResponseMsg":
            // should we check if the response msg matches the inFlight tracker?
            let searchChanged = msg.key !== model.currentShownKey;
            let searchBaseItems = (msg.key && !searchChanged ? model.postItemsSearch : !msg.key ? model.postItemsDefault : undefined);
            let keepPrevious = !searchChanged && searchBaseItems !== undefined && searchBaseItems.items.length > 0
            let nextItems: KeyedItemState | DefaultItemState = { key: msg.key, items: getItemsNext(keepPrevious, searchBaseItems?.items ?? [], msg.items), nextUrl: msg.nextUrl };

            let nextSS: SearchState = {
                // currentItems: nextItems,
                currentShownKey: msg.key,
                inFlightSearch: undefined,
                isHome: model.isHome,
                loadingShownKey: false,
                // next: undefined,
                postItemsDefault: msg.key ? model.postItemsDefault : (nextItems as DefaultItemState),
                postItemsSearch: msg.key ? (nextItems as KeyedItemState) : model.postItemsSearch,
                searchFocused: model.searchFocused
            }

            let nextState: SearchStateUpdateResult = {
                newState: nextSS,
                cmds: []
            };

            return nextState;

        case "RefocusRequestedMsg": // this could be user click
            if (!model.isHome) {
                return doNothing;
            }
            let nextFocusModel = { newState: { ...model, searchFocused: msg.focusType !== 'WantsUnfocus' }, cmds: [] }
            console.log('focusUpdated?', JSON.stringify({ willFocus: nextFocusModel.newState.searchFocused, userRequest: msg.userRequest, focusType: msg.focusType }));
            return nextFocusModel;
    }

    return assertUnreachable(msg);
};

// for example: if we leave home, search state and last search must be cleared
let sideEffects = (msgModel: SearchStateUpdateMsg, next: SearchStateUpdateResult): SearchStateUpdateResult => {
    // should we try to detect no changes before processing?

    return next;
}

export type SearchPayload = { msg: SearchMsg, cmdDispatch: CmdDispatch<SearchCmd> };

export type LikeUnlikePayload = { msg: LikeUnlikeMsg, cmdDispatch: CmdDispatch<SearchCmd> };

const searchSlice = createSlice({
    name: "search",
    initialState,
    reducers: {
        update(state, action: PayloadAction<SearchPayload>): SearchState {
            let x = { model: state, msg: action.payload.msg }
            console.info('searchSlice.update', JSON.stringify({ msgType: action.payload.msg.type, loadingShownKey: state.loadingShownKey, inFlight: state.inFlightSearch, focusType: (action.payload.msg as RefocusRequestedMsg)?.focusType }));
            let beforeSide = updateBase(x);
            // console.log('searchSlice.update.beforeSide', JSON.stringify({ msgType: action.payload.msg.type }));
            let afterSide = sideEffects(x, beforeSide);
            // console.log('searchSlice.update.afterSide', JSON.stringify({ msgType: action.payload.msg.type }));

            // are we potentially ignoring beforeSide commands?
            action.payload.cmdDispatch(afterSide.cmds);
            // console.log('searchSlice.update.cmdsDispatched', JSON.stringify({ msgType: action.payload.msg.type }));
            return afterSide.newState;
        },
        updateLikeUnlike(state, action: PayloadAction<LikeUnlikePayload>): SearchState {

            let msg = action.payload.msg;
            console.info('searchSlice.updateLikeUnlike', JSON.stringify({ msgType: action.payload.msg.type }));

            switch (msg.type) {

                case "LLoadResponseMsg":
                    // onLikeLoadRequestHandler
                    // this type must match the property of serverPost item, but is required 
                    let li: Exclude<ServerPostItem['postItemMetaInfo'], undefined> = msg.likeInfo;
                    let updateResult = tryUpdateItem(state)(msg.item, x => ({ ...x, postItemMetaInfo: li }));
                    if (updateResult.state === "error") {
                        console.warn(msg.type + ": update failed", JSON.stringify({ key: msg.item.id, currentShownKey: state.currentShownKey, error: updateResult.error }));
                        return state;
                    }

                    return updateResult.value;

                case "LikeLoadRequestMsg":
                    // send out a command, if we aren't already in flight for this item? maybe that's not a thing to worry about
                    if (!msg.item.postItemMetaInfo)
                        action.payload.cmdDispatch([
                            { type: "LoadLikeInfoCmd", item: msg.item }
                        ]);
                    return state;

                case "LRequestMsg":
                    // IIFE function so I can use the same names for the same ideas in different branches.
                    return (function () {
                        let item = msg.item;
                        if (!item.postItemMetaInfo || !item.addLikeUrl || !item.removeLikeUrl) {
                            console.warn(msg + ": something missing", JSON.stringify({ li: item.postItemMetaInfo, alu: item.addLikeUrl, rlu: item.removeLikeUrl }));
                            return state;
                        }
                        let lrResult = tryUpdateItem(state)(item, x => {
                            if (!x.postItemMetaInfo) {
                                console.warn(msg.type + ": item not found", JSON.stringify({ currentShownKey: state.currentShownKey, id: item.id, c: x.id, li: x ? x.postItemMetaInfo : 'no item' }));
                                return x;
                            }
                            let iLike = !x.postItemMetaInfo.iLike;
                            let likes = (x.postItemMetaInfo?.likes ?? 0) + (iLike ? 1 : -1);
                            let likeInfo: ServerPostItem['postItemMetaInfo'] = { iLike, likes: likes, totalClicks: x.postItemMetaInfo?.totalClicks ?? item.postItemMetaInfo?.totalClicks ?? -1 }
                            return { ...x, postItemMetaInfo: likeInfo } as ServerPostItem;
                        });

                        if (lrResult.state === "error") {
                            console.warn(msg.type + ": " + lrResult.error, JSON.stringify({}))
                            return state;
                        }

                        return lrResult.value;
                    })();
            }
            return assertUnreachable(msg);
        }

    }
});

export type SearchCmdDispatch = CmdDispatch<SearchCmd>
// : CmdDispatch<SearchCmd> 
export const makeSearchCmdDispatch = (dispatch: Function, runner: CmdRunner<SearchCmd>) => makeCmdDispatch<SearchCmd>(dispatch, runner) as SearchCmdDispatch

// export type SearchMsgDispatch = 
export const searchActions = searchSlice.actions;

export default searchSlice;